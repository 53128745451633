<template>
  <b-modal id="EmployeeBranchInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/employee-branches.svg" class="icon-lg" />
        {{ $t("employee-branches-msgs.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeBranchData.employeeNameCurrent"
        :title="$t('employeeName')"
        :imgName="'employees.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeBranchData.branchNameCurrent"
        :title="$t('branchName')"
        :imgName="'branches.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeBranchData.branchRoleNameCurrent"
        :title="$t('setting.others.branchRolesMsgs.branchRole')"
        :imgName="'branch-roles.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeBranchData.employeeWorkPlaceTypeNameCurrent"
        :title="$t('employeeWorkPlaceType')"
        :imgName="'type.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeBranchData.employeeBranchesNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          formateDateTimeLang(
            employeeBranchData.actionDate,
            employeeBranchData.actionTime
          )
        "
        :title="$t('actionDateTime')"
        :imgName="'dateAndTime.svg'"
      />

      <b-button v-b-toggle.employeeJobs class="btn btn-lg btn-collapse col-12">
        {{ $t("employeeJobs") }}
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </b-button>
      <b-collapse visible id="employeeJobs" class="col-12">
        <div class="table-container">
          <table class="my-table">
            <thead>
              <tr>
                <th>#</th>
                <th>{{ $t("jobName") }}</th>
                <th>{{ $t("Sectors.name") }}</th>
              </tr>
            </thead>
            <tbody
              v-if="
                employeeBranchData.employeeBranchJobsData != undefined &&
                employeeBranchData.employeeBranchJobsData.length != 0
              "
            >
              <tr
                v-for="(
                  job, index
                ) in employeeBranchData.employeeBranchJobsData"
                :key="index"
              >
                <td>{{ ++index }}</td>
                <td>{{ isDataExist(job.jobNameCurrent) }}</td>
                <td>{{ isDataExist(job.sectorNameCurrent) }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <td colspan="3">{{ $t("thereAreNoJobs") }}</td>
            </tbody>
          </table>
        </div>
      </b-collapse>
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { formateDateTimeLang, isDataExist } from "@/utils/functions";

export default {
  name: "EmployeeBranchInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["employeeBranchData"],
  methods: {
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
    isDataExist,
  },
  async created() {},
};
</script>
